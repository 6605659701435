.mysteryBox {
    position: fixed;
    right: 0px;
    bottom: 0px;
    width: 200px;
    text-align: center;
    padding-bottom: 30px;
    color: #FFF;
    z-index: 1000;
}

.modalDetailBox {
    .lose {
        color: #FFF;

        svg {
            width: 40% !important;
            margin: 0 auto;
        }
    }
    .win {
        color: #FFF;
    }
}