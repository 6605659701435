#regulamentoFooter {
  position: relative;
  bottom: 0;
  width: 100%;
  left: 0;
  z-index: 50;
  color: #fff;

  .link {
    text-decoration: underline;
    cursor: pointer;
  }

  .showRegulamento {
    display: flex;
    margin: 10px auto 30px;
    font-size: 1.2em;
    font-weight: bold;

    u {
      margin-left: 5px;
    }

    .gg-chevron-up {
      box-sizing: border-box;
      position: relative;
      display: block;
      transform: scale(var(--ggs, 1));
      width: 22px;
      height: 22px;
      border: 2px solid transparent;
      border-radius: 100px;
      left: 8px;
      top: 4px;
    }

    .gg-chevron-up::after {
      content: "";
      display: block;
      box-sizing: border-box;
      position: absolute;
      width: 10px;
      height: 10px;
      border-top: 2px solid;
      border-right: 2px solid;
      transform: rotate(-45deg);
      left: 4px;
      bottom: 2px;
    }

    .gg-chevron-down {
      box-sizing: border-box;
      position: relative;
      display: block;
      transform: scale(var(--ggs, 1));
      width: 22px;
      height: 22px;
      border: 2px solid transparent;
      border-radius: 100px;
      left: 8px;
      top: 4px;
    }

    .gg-chevron-down::after {
      content: "";
      display: block;
      box-sizing: border-box;
      position: absolute;
      width: 10px;
      height: 10px;
      border-bottom: 2px solid;
      border-right: 2px solid;
      transform: rotate(45deg);
      left: 4px;
      top: 2px;
    }
  }

  .content {
    background: #f4f4fc;
    border-radius: 10px 10px 0 0;
    display: none;
    margin: 0 auto;
    position: fixed;
    width: 99vw;
    height: 70vh;
    left: 0;
    bottom: 0;
    padding-top: 4vh;
    color: #5d5d5d;

    h2 {
      color: #052886;
      font-size: 1.8em;
      font-weight: 300;
    }

    > div {
      max-width: 80%;
      margin: 0 auto;
      position: relative;
    }

    .track-vertical {
      position: absolute;
      width: 4px !important;
      right: 10px;
      bottom: 2px;
      top: 2px;
      border-radius: 3px;
      background-color: #e2e2e2;

      > div {
        width: 8px !important;
        background-color: #062b8c !important;
        right: 2px;
      }
    }

    .closeRegulation {
      background-color: #062b8c;
      color: #fff;
      position: absolute;
      right: 0px;
      padding: 0;
      border-radius: 100px;
      top: -50px;
      width: 25px;
      height: 25px;
    }

    .contentExtrato {
      height: 45vh !important;

      > div:first-child {
        padding: 0 50px;
      }
    }
  }

  .download {
    display: none;
    background: #88e1f2;
    color: #fff;
    border: 1px solid #ffffff;
    border-radius: 8px;
    padding: 10px 30px;
    margin: 20px auto;
    font-weight: bold;
    font-size: 1.1em;

    .gg-software-download {
      box-sizing: border-box;
      position: relative;
      display: block;
      transform: scale(var(--ggs, 1));
      width: 16px;
      height: 6px;
      border: 2px solid;
      border-top: 0;
      border-bottom-left-radius: 2px;
      border-bottom-right-radius: 2px;
      margin-top: 8px;
      left: 7px;
      top: 6px;
    }

    .gg-software-download::after {
      content: "";
      display: block;
      box-sizing: border-box;
      position: absolute;
      width: 8px;
      height: 8px;
      border-left: 2px solid;
      border-bottom: 2px solid;
      transform: rotate(-45deg);
      left: 2px;
      bottom: 4px;
    }

    .gg-software-download::before {
      content: "";
      display: block;
      box-sizing: border-box;
      position: absolute;
      border-radius: 3px;
      width: 2px;
      height: 10px;
      background: currentColor;
      left: 5px;
      bottom: 5px;
    }
  }

  &.open {
    .content {
      display: block;
    }
    .download {
      display: flex;
    }
  }
}
