#home-wrapper {
  // background: #1c5ddb url('/assets/img/bg-logado.jpg') no-repeat center;
  background-size: cover;
  overflow: hidden;
  position: relative;
  background-image: linear-gradient(#0d4cc8, #04217b);
  background: linear-gradient(180deg, #195ada 0%, #04227b 28.12%);

  .home {
    color: #fff;

    h2 {
      font-size: 2em;
      color: #88e1f2;
      text-transform: uppercase;
    }

    .bg-gradient-section {
      background: linear-gradient(180deg, #092d83 0%, #081c5a 100%);
    }

    .left {
    }
    .right {
    }

    .premios {
      background: url("/assets/img/bg-mobile.png") no-repeat center;
      background-size: cover;
      width: 100vw;
      margin: 0 -15px;

      > div {
        width: calc(100% / 2);
        min-height: 108px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        p {
          font-weight: bold;
        }
      }
    }

    .desafios {
      margin-bottom: 50px;

      h2 {
        margin-bottom: 20px;
      }
      // .item {
      //   padding: 10px;
      //   position: relative;
      //   margin: 0 10px 0 0;
      //   min-height: 315px;
      //   cursor: pointer;
      // }
      // .infoDesafio {
      //   position: absolute;
      //   left: -15px;
      //   top: -15px;
      //   z-index: 100;
      //   width: 115px;
      // }
      // .imageCardDesafios {
      //   position: absolute;
      //   left: 5px;
      //   top: 3px;
      //   z-index: 1;
      // }
      .contentText {
        position: absolute;
        z-index: 2;
        bottom: -10px;
        width: 95%;
        height: 150px;
        background-size: cover;
        padding: 50px 10px 0 10px;

        h3 {
          font-size: 1.5em;
          line-height: 1;
          margin-bottom: 10px;
        }

        font-weight: bold;

        p {
          font-weight: 300;
          color: #88e1f2;
        }
      }

      .react-multiple-carousel__arrow {
        background: #88e1f2;
        min-width: 33px;
        min-height: 33px;

        &:hover {
          background: #88e1f2;
        }
      }
      .react-multiple-carousel__arrow--right {
        right: calc(0% + 1px);
      }
      .react-multiple-carousel__arrow--left {
        left: calc(0% + 1px);
      }
    }

    .missao {
      margin-bottom: 50px;

      h2 {
        margin-bottom: 20px;
      }

      .contentCards {
        display: flex;

        .item {
          min-height: 300px;
          background: url("/assets/img/bg-1-missao.png") no-repeat center;
          background-size: 100% 100%;
          padding: 15px;
          min-width: 227px;

          &:nth-child(1) {
            position: relative;
            z-index: 3;
          }

          &:nth-child(2) {
            position: relative;
            z-index: 2;
            left: -12px;
            padding-left: 20px;
          }

          &:nth-child(3) {
            position: relative;
            z-index: 1;
            left: -25px;
            padding-left: 20px;
            background: transparent;
            background-image: linear-gradient(to right, #0d4cc8, #04217b);
          }

          img {
            margin-top: 20px;
            height: 80px;
          }

          h3 {
            font-size: 1.5em;
            font-weight: bold;
            font-style: italic;
            color: #cbb238;
            margin-bottom: 10px;
          }

          p {
            font-size: 0.9em;
          }
        }
      }
    }

    .videoCampanha {
      background-image: linear-gradient(to right, #0d4cc8, #04217b);
      background-size: cover;
      width: 100%;
      height: 150px;
      position: relative;

      > img {
        position: relative;
        z-index: 0;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }

      > div {
        display: flex;
        align-items: center;
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;

        p {
          width: 60%;
          padding-left: 100px;
          font-size: 1.4em;
        }
      }

      .btnSaibaMais {
        font-weight: bold;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 15px 19px;
        gap: 10px;
        width: 200px;
        height: 40px;
        background: #cbb238;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 7px;
        flex: none;
        order: 1;
        flex-grow: 0;
      }
    }

    .meusPremios {
      margin-bottom: 50px;

      h2 {
        margin-bottom: 20px;
      }
      .contentCards {
        display: flex;

        .item {
          width: 49%;
          text-align: center;
          min-height: 315px;
          background: #083298;
          margin: 0 10px 0 0;
          position: relative;

          span {
            position: absolute;
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #ff6363;
            box-shadow: 0px 6.08696px 6.08696px rgba(0, 0, 0, 0.25);
            border-radius: 100%;
            align-items: center;
            right: -10px;
            top: -10px;
          }

          > img {
            display: block;
            margin: 20px auto 0;
            width: 110px;
            height: 110px;
          }

          h3 {
            font-size: 1.5em;
          }

          > h3 {
            font-size: 2em;
            position: relative;
            margin-bottom: 10px;

            &::before {
              content: "";
              display: block;
              width: 32px;
              height: 2px;
              background-color: #88e1f2;
              position: absolute;
              bottom: 0;
              left: 50%;
              transform: translateX(-50%);
            }
          }

          a {
            position: absolute;
            bottom: 0;
            width: 100%;
            left: 0;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #88e1f2;
            color: #0c42c0;
            font-weight: bold;

            &:hover {
              text-decoration: none;
              color: #fff;
            }
          }
        }
      }
    }

    .performance {
      margin-bottom: 50px;

      h2 {
        margin-bottom: 20px;
      }

      .contentItems {
        min-height: 300px;
        background-image: linear-gradient(to right, #0d4cc8, #04217b);
        padding: 30px 40px 0 40px;

        h3 {
          font-size: 1.4em;
        }

        .react-multi-carousel-dot button {
          width: 15px;
          height: 15px;
        }
        .react-multi-carousel-dot--active button {
          background: #fff;
        }
        .react-multi-carousel-dot button:hover:active {
          background: #fff;
        }

        .item {
          min-height: 240px;
        }

        .contentProgress {
          width: 100%;
          height: 45px;
          margin-bottom: 20px;
          position: relative;

          > div {
            float: left;
          }
          .progress1 {
            background: #05237c;
            width: 249px;
            height: 100%;
            position: relative;

            span {
              position: absolute;
              right: 17px;
              top: 10px;
              z-index: 10;
            }

            .progress {
              width: 1px;
              background: linear-gradient(
                270.09deg,
                #cbb238 49.93%,
                #ffe493 102.55%
              );
              height: 100%;
              max-width: 249px;
              position: relative;
              z-index: 11;
            }

            .triangle-right {
              top: 0;
            }
          }
          .progress2 {
            background: linear-gradient(
              270.09deg,
              #cbb238 49.93%,
              #ffe493 102.55%
            );
            width: 60px;
            height: 100%;
            position: relative;

            img {
              position: absolute;
              left: -20px;
              top: -12px;
              z-index: 12;
            }

            span {
              position: absolute;
              right: 2px;
              top: 10px;
            }
          }

          .triangle-right {
            width: 0;
            height: 0;
            margin: 0px auto;
            border-top: 22px solid transparent;
            border-bottom: 23px solid transparent;
            border-left: 20px solid #cbb238;
            position: absolute;
            right: -20px;
            z-index: 10;

            &.blue {
              border-left: 20px solid #05237c;
              right: -20px;
            }
          }
        }
      }
    }

    .proximosDesafios {
      height: 150px;
      background-image: linear-gradient(to right, #0d4cc8, #04217b);

      p {
        font-size: 1.4em;
        text-align: center;
        font-size: 1.4em;
        text-align: center;
        margin: 15px auto;
        padding-top: 30px;
      }

      button {
        font-weight: bold;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 15px 19px;
        gap: 10px;

        width: 252px;
        height: 40px;

        background: #cbb238;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 7px;

        /* Inside auto layout */
        flex: none;
        order: 1;
        flex-grow: 0;

        &:hover {
          color: #fff;
          text-decoration: none;
        }
      }
    }

    .indicadores {
      border: 1px solid #88e1f2;
      padding: 50px 38px;
      position: relative;

      h3 {
        color: #88e1f2;
        font-size: 1.8em;
        text-transform: uppercase;
        font-weight: bold;

        span {
          color: #00adee;
        }
      }

      .title{
        align-items: flex-end;
        display: flex;
        

        @media screen and (max-width: 768px) {
          flex-direction: column;
          align-items: flex-start;
        }

        p{
          font-size: 16px;
          font-weight: 400;
          padding-left: 30px;
          line-height: 35px;

          @media screen and (max-width: 768px) {
            padding:0;
          }
        }
      }

      p {
        font-weight: 200;
      }

      .top-img {
        position: absolute;
        right: -20px;
        top: -20px;
        width: 60px;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #88e1f2;
        border-radius: 50%;
        background: #04227c;

        img {
          height: 30px;
        }
      }

      .img-down {
        top: -10px;

        img {
          position: absolute;
          right: 0px;
          top: 0px;
          height: 49px;
        }
      }

      .bar {
        > div {
          width: 100%;
          background-color: #081d5c;
          height: 45px;
          position: relative;
          margin-top: 20px;

          > span {
            position: absolute;
            top: 50%;
            // left: 50%;
            transform: translateY(-50%);
            color: rgba(255, 255, 255, 0.5);
            font-weight: 600;
          }

          .bar-end {
            position: absolute;
            right: 0;
            height: 100%;
            background: #0a1433;
            width: 55px;
          }

          .progress {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            background-color: #88e1f2;
            color: #05237c;
            display: flex;
            align-items: center;
            font-weight: 600;
            padding-left: 10px;
            z-index: 2;
          }

          .trophy {
            position: absolute;
            right: -10px;
            height: 60px;
            width: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #88e1f2;
            border-radius: 50%;
            top: 50%;
            transform: translateY(-50%);
            z-index: 3;

            img {
              height: 30px;
            }

            p {
              position: absolute;
              color: #88e1f2;
              bottom: -20px;
              font-weight: 600;
            }
          }

          .bateu,
          .superou {
            position: absolute;
            right: -25px;
            top: -130px;
            display: flex;
            align-items: center;
            // width: 130px;

            p {
              display: block;
              color: #88e1f2;
              font-weight: bold;
              text-transform: uppercase;
              margin-right: 10px;
            }

            span {
              position: absolute;
              bottom: -85px;
              color: #88e1f2;
            }

            > .img {
              border: 2.56993px solid #88e1f2;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 50px;
              height: 50px;
              position: relative;

              picture {
                width: 50px;
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                  height: 30px;
                }
              }

              &:before {
                content: "";
                display: block;
                width: 3px;
                height: 20px;
                border-left: 3px dotted #88e1f2;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                top: 50px;
                height: 122px;
              }
            }
          }

          .bateu {
            right: 30px;
            top: -65px;

            .img {
              &:before {
                height: 57px;
              }
            }
          }
        }
      }
    }
    .meses {
      border: 0.758107px solid #88e1f2;
      padding: 5px;
      display: flex;

      > div {
        width: 50%;
        display: flex;
        justify-content: center;
        border-top: 16px solid #061a59;
        background: #2d4aa1;

        &:first-child {
          margin-right: 2px;
        }

        p {
          color: #6b83cc;
          font-weight: 900;
          font-size: 15px;
        }
      }
    }
    .meses.concluido {
      > div {
        background: #fff;
        border-top: 16px solid #88e1f2;

        p {
          color: #04227c;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .home {
      width: 100%;
      .desafios {
        .item {
          min-height: 280px;
        }
        .contentText {
          position: absolute;
          z-index: 2;
          bottom: 3px;
          left: 7px;
          width: 95%;
          height: 124px;
          background-size: cover;
          padding: 40px 10px 0 10px;

          h3 {
            font-size: 1em;
            line-height: 1;
            margin-bottom: 10px;
            color: #fff;
          }

          p {
            color: #88e1f2;
          }
        }
      }

      .indicadores {
        padding: 35px 25px;
      }

      .videoCampanha {
        > div {
          p {
            width: 40%;
            padding-left: 30px;
            font-size: 1.2em;
          }
        }
      }

      .performance {
        .contentItems {
          padding: 20px 20px 0 20px;

          .contentProgress {
            .progress1 {
              background: #05237c;
              width: 70%;
            }
          }
        }
      }

      .proximosDesafios {
        height: 150px;
        background: #115cd9;
        box-sizing: border-box;
        width: 100vw;
        margin: 0 -15px;
      }
    }
  }
}
