#modalNotifications {
    position: relative;

    .btnClose {
        background-color: #88E1F2;
        position: absolute;
        right: 0;
        padding: 0;
        border-radius: 100px;
        top: -15px;

        span {
            padding: 5px 10px;
            border-radius: 100px;
        }
    }

    .listNotifications {
        padding: 0;
        max-height: 400px;
        overflow: auto;

        li {
            text-align: left;
            min-height: 40px;
            margin-bottom: 10px;
            border-bottom: 1px solid #88E1F2;
        }
    }
}