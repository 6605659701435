#pageMinhasEntregas {
    font-family: 'Inter';
    font-style: normal;
  
    h2 {
      font-size: 1.8em;
      color: #88E1F2;
      margin-bottom: 20px;
      text-transform: uppercase;
      font-weight: normal;
    }

    .table {
        color: #fff;
        width: 98%;
  
        thead {
          background-color: #00adee;
          position: sticky;
          top: 0;
  
          th {
            padding: 10px;
            text-align: left;

            &.text-right {
                text-align: right;
            }
            &.text-center {
                text-align: center;
            }
          }
        }
        tbody {
          td {
            padding: 15px;
            border-bottom: 1px solid #0c3dbd;
  
            .detalhamento {
              text-transform: uppercase;
            }

            &.text-right {
                text-align: right;
            }
            &.text-center {
                text-align: center;
            }
          }
        }
      }
}