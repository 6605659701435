#login-wrapper {
  // background: #173ebd url('/assets/img/login.jpg') no-repeat top, linear-gradient(180deg, #85D8F7 48.46%, #004281 99.8%);
  background-image: url('/assets/img/bg-login2.png'), linear-gradient(90deg, #1c5ddb 48.46%, #1635b4 99.8%);
  background-repeat: no-repeat, no-repeat;
  background-position: top, top;
  background-size: auto 100%;
  background-attachment: fixed;

  @media screen and (max-width: 768px) {
    background: #1c5ddb url('/assets/img/bg-login-mobile2.png') no-repeat top;
    background-size: auto 42vh;
    // background-attachment: scroll;
  }
}