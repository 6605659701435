#modalVideo {
    position: relative;

    
    .btnClose {
        background-color: #CBB238;
        position: absolute;
        right: 0;
        padding: 0;
        border-radius: 100px;
        top: -15px;

        span {
            padding: 5px 10px;
            border-radius: 100px;
        }
    }
}