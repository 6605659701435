#container-menu {
  display: flex;
  justify-content: end;

  a {
    &.active {
      font-weight: bold;
      color: #88e1f2;
    }
  }

  .unidade {
    p {
      color: #fff;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0px 10px;
      gap: 10px;
      width: 37%;
      height: 30px;
      border: 1px solid #fff;
      margin: 0;
      font-size: 0.7em;
      text-align: center;
    }
    .points {
      width: 50%;
      color: #fbd955;
      font-size: 1.3em;

      p {
        width: 100%;
        color: #88e1f2;
      }

      img {
        width: 30px;
      }
    }
  }

  span.total {
    position: absolute;
    color: #fff;
    background-color: red;
    padding: 0px 6px;
    border-radius: 100%;
    top: 2px;
    display: flex;
    right: -8px;
    font-weight: normal;
 }

  @media screen and (max-width: 768px) {
    display: none;
  }
}
