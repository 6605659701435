.juka {
  display: flex;
  background: #00adee;
  width: 100%;
  height: 110px;
  color: #fff;

  > div {
    // display: flex;
    width: 50%;
    align-items: center;
    padding: 5px 20px;

    p {
      font-size: 1.1em;
    }

    &.showMore {
      .content {
        margin-top: 20px;
      }
    }
  }

  .imageJuka {
    position: relative;

    img {
      border: 5px solid #00adee;
      border-radius: 100%;
      position: relative;
      left: -10px;
      top: -20px;
      float: left;
    }

    p {
      display: block;
      align-items: center;
      height: 100px;

      span {
        display: block;
        width: 100%;
        position: relative;
        top: 20px;
        font-size: 1.3em;
      }

      button {
        font-size: 12px;
        position: relative;
        bottom: -17px;
      }
    }

    .close {
      position: absolute;
      right: -10px;
      top: -10px;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      font-size: 0.9em;
      font-weight: bold;
      background: #88e1f2;
      box-shadow: 0px 6.08696px 6.08696px rgba(0, 0, 0, 0.25);
      border-radius: 62.3913px;
    }
  }
}

#user-info {
  //background: url('/assets/img/bg-header.svg') no-repeat center;
  background-size: auto 100%;
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin-bottom: 50px;
  justify-content: center;

  .list {
    list-style: initial;
    margin: initial;
    padding: 0 0 0 40px;

    li {
      display: list-item;
    }
  }

  .logo {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .menu {
    // width: 55%;
    // display: flex;
    padding: 0 30px;

  }
  .infosUser {
    width: 25%;
    display: flex;
    color: #fff;

    .image {
      width: 120px;
      height: 120px;
    }

    .editButton {
      position: relative;
      margin: 0 auto;
      z-index: 2;
      margin-top: -8px;
      color: #0a3cab;
      background: #88e1f2;
      box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.25);
      border-radius: 0;
      display: flex;
      width: 95px;
      justify-content: center;
      align-items: center;
      padding: 4px 0;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 0.8em;

      svg {
        margin-right: 5px;
      }
    }

    .points {
      display: flex;
      margin-top: 20px;

      > div {
        width: 50%;
        position: relative;

        img {
          position: relative;
          width: 56px;
          height: 64px;
        }
      }

      .bgPoint {
        width: 100%;
        background: #ffffff;
        border-radius: 20px;
        height: 15px;
        width: 31px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        color: #00adee;
        bottom: -4px;
        right: 20px;
        margin: 0 auto;
        position: absolute;
      }
    }

    .notifications {
      position: absolute;
      right: 25px;
      background-color: #ff6363;
      border-radius: 100%;
      width: 31px;
      height: 31px;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 10;
    }
  }
}


  
.navlink {
  display: flex;
  justify-content: center;

  a {
    border: 1px solid #fff;
    border-radius: 0;
    padding: 5px 15px;
    font-weight: bold;
    margin-right: 10px;

    &:hover {
      text-decoration: none;
    }
    &.menuActive {
      background: #88e1f2;
      box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.32);
      color: #072e92;
      border-radius: 0;
      border: 0;
    }
  }
}

.unidade-user-info {
  p {
    color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 10px;
    gap: 10px;
    width: 48%;
    height: 30px;
    margin: 0;
    font-size: 0.6em;
    border: 0.5px solid #ffffff;
    // border-right: 0;
  }
  .points {
    border: 0.5px solid #ffffff;
    color: #88e1f2;
    font-size: 1.3em;

    img {
      width: 20px;
    }
  }
}

@media screen and (max-width: 768px) {
  #user-info {
    margin-bottom: 0;

    .logo {
      width: 50%;
      justify-content: start;
      a {
        margin: 0 auto;
      }
      img {
        width: 100%;
        object-fit: contain;
      }
    }
    .infosUser {
      width: 50%;
      .image {
        margin-left: auto;
        width: 73px;
        height: 73px;
      }
      .points {
        > div {
          img {
            width: 40px;
            height: 47px;
          }

          .bgPoint {
            padding: 0;

            right: 0;
          }
        }
      }

      .editButton {
        width: 60px;
        color: #fff;
        font-size: 0.7em;

        padding: 0 0;

        svg {
          margin-right: 5px;
          width: 10px;
        }
      }
    }
    .menu {
      display: none;
    }
  }

  .juka {
    display: none;
  }

  .juka-mobile {
    display: flex;
    background: #00adee;
    width: 100%;
    min-height: 32px;
    color: #fff;

    > div {
      // display: flex;
      width: 50%;
      align-items: center;
      padding: 5px 10px;

      p {
        font-size: 0.7em;
      }
    }

    .imageJuka {
      position: relative;

      img {
        border: 5px solid #00adee;
        border-radius: 100%;
        position: relative;
        left: -10px;
        width: 47px;
        height: 47px;
      }

      p {
        display: flex;
        align-items: center;

        span {
          cursor: pointer;
        }
      }

      // button {
      //   position: absolute;
      //   right: -10px;
      //   top: -10px;
      //   background-color: #cbb238;
      //   width: 20px;
      //   height: 20px;
      //   text-align: center;
      //   border-radius: 10px;
      //   font-size: 0.9em;
      //   font-weight: bold;
      // }
    }
  }

  .unidade-user-info {
    a {
      margin-left: 10px;
        p {
          width: auto;
          font-size: 0.5em;
          border: none;
          padding: 0 9px;
          color: #88e1f2;
        }
      }
  }

  .navlink {
    display: none;
  }
}

.box {
  background: url("/assets/img/box.svg") no-repeat left;
  background-size: 100% 100%;
  &.box-responsive {
    @media screen and (max-width: 768px) {
      background: #0930b4;
      border-radius: 10px;
    }
  }
}

.reactour__popover {
  padding: 40px !important;
}