#aCampanha {
  .react-multi-carousel-dot button {
    width: 15px;
    height: 15px;
    background: #d9d9d9;
    border: 0;
  }
  .react-multi-carousel-dot--active button {
    background: #3abded;
  }
  .react-multi-carousel-dot button:hover:active {
    background: #3abded;
  }

  .react-multiple-carousel__arrow::before {
    display: none;
  }

  .react-multiple-carousel__arrow {
    background: url(/assets/img/arrow-left.svg) no-repeat;
    background-size: cover;
    min-height: 39px;
    min-width: 25px;
  }
  .react-multiple-carousel__arrow--left {
    left: 0;
  }
  .react-multiple-carousel__arrow--right {
    background: url(/assets/img/arrow-right.svg) no-repeat;
    right: 0;
  }
}
