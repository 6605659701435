#contact-wrapper {
  background: url('/assets/img/bg-logado.jpg') no-repeat center;
  background-size: cover;
  background-attachment: fixed;
  
}

.contact-wrapper {
  * {
    color: #FFF;
  }

  h2 {
    margin-bottom: 20px;
  }

  input, textarea, select {
    color: #000
  }

  .btnEnviar {
    button {
      border-radius: 0;
      background-color: #00ADEE;
      text-transform: uppercase;
    }
  }

  .Collapsible {
    display: block;
    text-decoration: none;
    position: relative;
    border: 1px solid #FFF;    
    color: white;
    margin-bottom: 5px;

    .Collapsible__trigger {
      display: flex;
      padding: 25px;
      align-items: center;
      justify-content: flex-start;

      > span {
        font-size: 1.2em;

        b {
          margin-right: 20px;
          font-size: 1.4em;
          color: #00ADEE;
        }
      }

      &.is-closed {
        &::after {
          content: '+';
          position: absolute;
          right: 15px;
          top: 30px;
          display: block;
          background-color: #00ADEE;
          border-radius: 100%;
          font-size: 1.7em;
          line-height: 0.9;
          width: 25px;
          height: 25px;
          text-align: center;
        }
      }
      &.is-open {
        &::after {
          content: '-';
          position: absolute;
          right: 15px;
          top: 30px;
          display: block;
          background-color: #00ADEE;
          border-radius: 100%;
          font-size: 1.7em;
          line-height: 0.9;
          width: 25px;
          height: 25px;
          text-align: center;
        }
      }
    }

    .Collapsible__contentOuter {
      padding: 0 25px;
    }

    .Collapsible__contentInner {
      > p {
        margin-bottom: 20px;
      }
    }
  }
}