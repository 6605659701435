#filterExtrato {
  font-family: 'Inter';
  font-style: normal;

  h2 {
    font-size: 1.8em;
    color: #88E1F2;
    margin-bottom: 20px;
    text-transform: uppercase;
    font-weight: normal;
  }

  .filterDate {
    form {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      > div {
        margin-right: 10px;

        label {
          color: #fff;
        }

        input[type="text"] {
          height: 35px;
          background-color: transparent;
          color: #fff;
          border: 0.699557px solid #ffffff;
          border-radius: 6.29601px;
        }

        input::placeholder {
          color: #fff;
        }

        > button {
          margin: 0 auto;
          background-color: #88e1f2;
          color: #072e92;
          border: 1px solid #88e1f2;
          padding: 3px 20px;
          border-radius: 5px;
          font-weight: 700;
        }
      }
    }
  }
}
#home-wrapper {
  .filter {
    margin-top: 0;
    .active {
      background-color: #88E1F2;
    }
  }
}
#extrato {
  margin-top: 0;
  padding: 0 32px;
  font-family: 'Inter';
  font-style: normal;

  .contentExtrato {
    .table {
      color: #fff;
      width: 98%;

      thead {
        background-color: #00adee;
        position: sticky;
        top: 0;

        th {
          padding: 10px;
          text-align: left;
        }
      }
      tbody {
        td {
          padding: 15px;
          border-bottom: 1px solid #0c3dbd;

          .detalhamento {
            text-transform: uppercase;
          }
        }
      }
    }
  }
  .track-vertical {
    position: absolute;
    width: 4px !important;
    right: 10px;
    bottom: 2px;
    top: 2px;
    border-radius: 3px;
    background-color: #e2e2e2;

    > div {
      width: 8px !important;
      background-color: #88e1f2 !important;
      right: 2px;
    }
  }

  .loading {
    font-size: 1.5em;
    margin: 3% auto;
    color: #fff;
  }

  @media screen and (max-width: 768px) {
    padding: 0 15px;

    .contentExtrato {
      .table {
        color: #fff;
        width: 98%;

        thead {
          background-color: transparent;

          th {
            background-color: #00adee;
          }
        }
      }
    }
  }
}
