#modalRoleta {
    position: relative;

    
    .btnClose {
        background-color: #88e1f2;
        position: absolute;
        right: 0;
        padding: 0;
        border-radius: 100px;
        top: -15px;
        color: #fff;

        span {
            padding: 5px 10px;
            border-radius: 100px;
        }
    }

    .bhdLno{
        margin: 0 auto;
        position: relative;
        margin-top: 50px;
    }

    .bola {
        position: absolute;
        background-color: #fff;
        width: 130px;
        height: 130px;
        top: 290px;
        z-index: 99999999;
        border-radius: 100%;
        left: 269px;
        box-shadow: 1px 4px 10px 2px #000;
        border: none;
        font-weight: bold;
        font-size: 20px;

        &.locked {
            font-size: 48px;
        }
    }

    @media screen and (max-width: 768px) {        
        h3 {
            font-size: 1em;
        }

        .bola {
            width: 80px;
            height: 80px;
            top: 34.9%;
            left: 39.5%;
            font-size: 14px;
        }    
        
        .bhdLno {
            margin-top: 15px;
        }
    }
}
