#modalJuka {
    position: relative;

    .btnClose {
        background-color: #88E1F2;
        position: absolute;
        right: 0;
        padding: 0;
        border-radius: 100px;
        top: -15px;

        span {
            padding: 5px 10px;
            border-radius: 100px;
        }
    }

    .track-vertical {
        position: absolute;
        width: 4px !important;
        right: 10px;
        bottom: 2px;
        top: 2px;
        border-radius: 3px;
        background-color: #e2e2e2;
    
        > div {
          width: 8px !important;
          background-color: #88e1f2 !important;
          right: 2px;
        }
      }

    .listDicas {
        padding: 0;
        width: 95%;
        overflow: auto;

        li {
            text-align: left;
            min-height: 40px;
            margin-bottom: 10px;
            border-bottom: 1px solid #88E1F2;
            padding: 9px 10px;
            background: #00adee;
            color: #fff;
        }
    }
}