#pageChallenges {
  font-family: 'Inter';
  font-style: normal;

  h2 {
    font-size: 1.9em;
    color: #88E1F2;
    margin-bottom: 20px;
    text-transform: uppercase;
    font-weight: 300;

    > strong{
      font-weight: 900;
    }

    @media screen and (max-width: 768px) {
      line-height: 31px;
    }
  }
  
  .bgShadow {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }

  
  // .imageCardDesafios {
  //   position: absolute;
  //   left: 5px;
  //   top: 3px;
  //   z-index: 1;
  //   max-width: 95%;
  // }
  .contentText {
    position: absolute;
    z-index: 2;
    bottom: 0;
    width: 95%;
    height: 160px;
    //background: url("/assets/img/bg-txt-desafios.png") no-repeat center;
    background-size: cover;
    padding: 50px 10px 0 10px;

    h3 {
      font-size: 1.5em;
      line-height: 1;
      margin-bottom: 10px;
      color: #fff;
    }

    p {
      color: #88E1F2;
    }
  }

  .noChallenges {
    font-size: 1.5em;
    margin: 80px auto;
    color: #fff;
  }

  .react-multiple-carousel__arrow {
    background: #88E1F2;
    min-width: 33px;
    min-height: 33px;

    &:hover {
      background: #88E1F2;
    }
  }
  .react-multiple-carousel__arrow--right {
    right: calc(0% + 1px);
  }
  .react-multiple-carousel__arrow--left {
    left: calc(0% + 1px);
  }

  @media screen and (max-width: 768px) {
    .item {
      min-height: 250px;
    }

    .contentText {
      position: absolute;
      z-index: 2;
      bottom: 0;
      left: 5px;
      width: 95%;
      height: 124px;
      background-size: cover;
      padding: 40px 10px 0 10px;

      h3 {
        font-size: 1em;
        line-height: 1;
        word-spacing: 9999px;
        margin-bottom: 10px;
        color: #fff;
      }

      p {
        color: #fbd751;
      }
    }

    .carousel {
      margin-right: -26px;
    }
  }
}
