#modalDetailChallenge {
  position: relative;

  .imageDetail {
    max-width: 100%;
    // margin: 10px auto;
    display: block;
    max-height: 600px;
    min-width: 673px;
    min-height: 600px;
  }

  .btnClose {
    background-color: #88e1f2;
    position: absolute;
    right: 0;
    padding: 0;
    border-radius: 100px;
    top: -15px;

    span {
      padding: 5px 10px;
      border-radius: 100px;
    }
  }

  .infoDesafio {
    position: absolute;
    left: 0px;
    top: -19px;
    z-index: 100;
    width: 115px;
  }
}

@media only screen and (max-width: 600px) {
  #modalDetailChallenge {
    position: relative;
  
    .imageDetail {
      min-width: auto;
      min-height: auto;
    }
  }
}
