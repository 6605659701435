#pageChallengesDetail {
  font-family: "Inter";
  font-style: normal;

  h2 {
    font-size: 1.8em;
    margin-bottom: 5px;
    text-transform: uppercase;
    font-weight: bold;
  }

  span {
    color: var(--axis-color);
    // font-size: 10px;
    font-weight: 900;
    letter-spacing: 0.5em;
    line-height: 12px;
    text-transform: uppercase;
  }

  table {
    border-collapse: collapse;
    width: 100%;
    color: #15437e;

    th,
    td {
      padding: 8px;
      text-align: left;
      border-bottom: 3px solid #fff;
    }

    thead {
      tr {
        background-color: #d9d9d9;
      }
    }

    tbody {
      tr {
        background-color: #f7f7f7;
      }
    }
  }

  .pagination {
    display: flex;
    list-style: none;
    justify-content: center;
    align-items: center;
    padding: 0;
  }
  
  .pagination li {
    margin: 0 5px;
    display: inline-block;
  }
  
  .pagination a {
    display: block;
    padding: 8px 12px;
    border: 1px solid #ccc;
    color: #333;
    text-decoration: none;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .pagination a:hover {
    background-color: #f5f5f5;
  }
  
  .pagination .selected a {
    background-color: #007bff;
    color: #fff;
    border-color: #007bff;
  }
  
  .pagination .break a {
    cursor: default;
  }
  
}
