#menu {
  // background: url(/assets/img/menu.svg) no-repeat center;
  // background-size: 100% 100%;
  background: #fff;
  z-index: 100;

  a.active {
    background: #0d4ac5;
    color: #fff;
  }
}
