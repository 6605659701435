.challenge-card{
    padding: 10px;
    position: relative;
    margin: 0 10px 0 0;
    min-height: 325px;
    min-width:240px ;
    cursor: pointer;
    color: var(--text-color);

    .image{
        align-items: center;
        background:var(--axis-color);
        display: flex;
        justify-content: center;
        margin-bottom: 22px;
        padding:60px 70px;
        min-height: 202px;
    }

    &.disabled {
        filter: grayscale(100%);
    }
    

    &.expired{
        .image{
            background:#4E4E4E;

            img{
                filter: grayscale(100%);
                opacity: 0.4;
            }
        }
    }

    .infoDesafio {
        position: absolute;
        left: -9px;
        top: -8px;
        z-index: 100;
        width: 115px;
    }

    .content{
        position: relative;
        &:before{
            border-left:2px solid var(--axis-color); 
            content:'';
            display: block;
            height: 98%;
            left:0;
            position: absolute;
            top:0;
            width:2px;
        }

        .wrapper{
            padding-left:11px;

            span{
                color:var(--axis-color);
                font-size: 10px;
                font-weight: 900;
                letter-spacing: 0.5em;
                line-height: 12px;
                text-transform: uppercase;
            }

            h3{
                font-size: 23px;
                font-weight: 700;
                line-height: 110%;
                margin-top:2px;
                color: var(--text-color);
            }
        }
    }
}