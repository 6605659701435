#notificacoesModal{
    position: relative;
    
    .achievement-header{
        background: url(/assets/img/bg-achievement-header.png) no-repeat center;
        background-size: contain;
    }

    .btnClose {
        background-color: #88E1F2;
        position: absolute;
        right: 0;
        padding: 0;
        border-radius: 100px;
        top: -15px;

        span {
            padding: 5px 10px;
            border-radius: 100px;
        }
    }   
}